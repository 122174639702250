@import "../base/variables/colors.scss";

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

williams-ui-platform-root {
  height: 100%;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  overflow: auto;
}

a {
  text-decoration: none;
}

.homePage {
  background-image: url("../../libs/w-ui-angular/src/lib/assets/images/homePage.png");
}

.error {
  font-size: 14px;
  color: $error-text;
  font-style : italic;
}

.sm-error {
  font-size: 10px;
  color: $error-text;
  font-style : italic;
}

.row-error-cls {
  background: $row-error-cls;
  .k-grid-content-sticky{
    background: $row-error-cls;
  }
}

.row-disabled-selectable {
  td:not(.k-grid-content-sticky) {
    opacity: 0.6;
  }
}

.group-row-cls {
  background: linear-gradient(0deg, #94D5F8, #94D5F8), #FFFFFF;
  .k-grid-content-sticky {
    background: linear-gradient(0deg, #94D5F8, #94D5F8), #FFFFFF;
  }
}
//accounting-lockdown
tr.row-readonly{
  background: rgba(127, 133, 220, 0.08) !important;
}

.dateInfo {
  span {
    color:$page-title;
    font-weight: $bold-font-weight;
    font-size: $text-font-size;
    font-family: $helvetica-font-family;
  }
  :last-child {
    font-weight: $normal-font-weight;
    color: $grey-color;
  }
}

.card {
  background-color: $card-bg;
  box-shadow: $card-box-shadow;
  border-radius: $card-border-radius;
  padding: 8px;
}

.height-100-percent {
  height: 100%;
}



::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #C2C2C5;
  outline: 1px solid #C2C2C5;
  border-radius: 8px;
}

// Styles for markjs
mark {
  padding: 0;
  background-color: yellow;
}