/** 
We can define scss variables here
*/
@import "../kendo-varibles-overwrite.scss";
$primary-color: #ff3a00;
$body-background-color: rgba(3, 125, 165, 0.08);
$subtle-text:  #707479;

$white-background-color:#fff;
$primary-color-dark: #af2900;
$nav-font-color: #fff;
$primary-text-color: #000;
$page-background: rgba(245, 248, 252, 0.4);
$page-heading: rgba(0, 0, 0, 0.87);
$page-title: #4E545A;

/* Sidebar */

$sidebar-background: #171a1e;
$sidebar-text: #a0a0a0;
$sidebar-active-text: #ffffff;

$primary-button-background: linear-gradient(0deg, #0079c1, #0079c1),
  linear-gradient(
    180deg,
    rgba(16, 24, 32, 0) 0%,
    rgba(0, 0, 0, 0.04) 99.48%,
    rgba(16, 24, 32, 0.04) 99.49%
  ),
  linear-gradient(0deg, #0079c1, #0079c1);
$primary-button-border: #0079c1;
$primary-button-text: #ffffff;
$clear-button-text: #40464D;

$default-button-background: linear-gradient(
    180deg,
    rgba(16, 24, 32, 2e-6) 0%,
    rgba(16, 24, 32, 0.02) 100%
  ),
  #f5f6f6;
$default-button-border: rgba(16, 24, 32, 0.3);
$default-button-text: #4e545a;
$grey-color:#4E545A;

$card-bg: #ffffff;
$card-box-shadow: 0px 1px 1px rgba(16, 24, 32, 0.16);
$card-border-radius: 2px;

$checkbox-bg: #0079c1;
$checkbox-border: rgba(16, 24, 32, 0.3);
$checkbox-border-radius: 2px;

//Toast Notification
$success-notification-bg: #32833f;
$success-notification-text: #ffffff;
$success-notification-box-shadow: 0px 2px 4px rgba(16, 24, 32, 0.03),
  0px 4px 5px rgba(16, 24, 32, 0.04);

// Dialog
$dialog-title-bg: #fff;
$dialog-title-text: #101820;
$dialog-content-text: #707479;

$input-border: rgba(15, 34, 88, 0.3);
$input-error-border: #ED0404;
$caret-icon:#9FA3A6;
$dropdown-background: linear-gradient(
    180deg,
    rgba(16, 24, 32, 2e-6) 0%,
    rgba(16, 24, 32, 0.02) 100%
  ),
  #f5f6f6;

$row-error-cls:linear-gradient(0deg, rgba(237, 4, 4, 0.12), rgba(237, 4, 4, 0.12)), #FFFFFF;
$error-text: #ED0404ed;

$header-border:1px solid rgba(0, 0, 0, 0.08);

// ListView

$list-view-border: rgba(16, 24, 32, 0.3);
$list-view-item-border: rgba(78, 84, 84, 0.12);

// Tab-Layout
$tab-background-color: #f0f4f6;
$tab-box-shadow: #ccc 0 0 0.25em;
$tab-layout-background: rgba(3, 125, 165, 0.08);
$selected-tab-text-color: #1274AC;

//Content Display
$content-area-border-color: rgba(16, 24, 32, 0.3);

// Multisslect
$checkbox-label-box-shadow: inset 0 0 0 2px rgb(0 0 0 / 12%);
$checkbox-label-bg : #f5f5f5;

//Text-Area
$textarea-border-color : rgba(0, 0, 0, 0.20);

$bluish: #354573 !default;