.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}
.flex-content-start {
  align-content: flex-start;
}
.flex-content-end {
  align-content: flex-end;
}
.flex-content-center {
  align-content: center;
}
.flex-content-between {
  align-content: space-between;
}
.flex-content-around {
  align-content: space-around;
}
.flex-content-stretch {
  align-content: stretch;
}
.flex-align-center {
  align-items: center;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-align-end {
  align-items: end;
}
.flex-content-align-items {
  align-items: baseline;
}
.flex-0 {
  flex: 0 0 260px !important
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-4 {
  flex: 4;
}
.flex-6 {
  flex: 6;
}
.flex-8 {
  flex: 8;
}
.flex-9{
  flex: 9;
}
.flex-10 {
  flex: 10;
}
.flex-12 {
  flex: 12;
}
.flex-auto {
  flex: auto;
}
.flex-grow-1{
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-self-auto {
  align-self: auto;
}
.flex-self-start {
  align-self: flex-start;
}
.flex-align-items-start {
  align-items: flex-start;
}
.flex-self-end {
  align-self: flex-end;
}
.flex-self-center {
  align-self: center;
}
.flex-self-baseline {
  align-self: baseline;
}
.flex-self-stretch {
  align-self: stretch;
}
.flex-order-1 {
  order: 1;
}
.flex-order-2 {
  order: 2;
}
.flex-order-none {
  order: inherit;
}
.flex-basis-full {
  flex-basis: 100%;
  flex-grow: 1;
}

.flex-basis-auto {
  flex-basis: auto;
}

.flex-gap-4 {
  gap: 4px;
}

.flex-gap-8 {
  gap: 8px;
}

.flex-gap-12 {
  gap: 12px;
}

.flex-gap-16 {
  gap: 16px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-24 {
  gap: 24px;
}

.flex-grow-1 {
  flex-grow: 1;
}
