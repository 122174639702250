// // Window
$kendo-window-border-width: 1px !default;
$kendo-window-border-radius: 4px !default;
// $kendo-window-font-family: $kendo-font-family !default;
// $kendo-window-font-size: $font-size !default;
// $kendo-window-line-height: 1.25 !default;

$kendo-window-titlebar-padding-x: map-get($spacing, 5) !default;
// $kendo-window-titlebar-padding-y: 12px !default;
// $kendo-window-titlebar-border-width: 0 0 1px !default;
// $kendo-window-titlebar-border-style: solid !default;

$kendo-window-title-font-size: $font-size-sxl !default;
// $kendo-window-title-line-height: 1.25 !default;

// $kendo-window-actions-gap: null !default;

// $kendo-window-action-opacity: null !default;
// $kendo-window-action-hover-opacity: null !default;

// $kendo-window-inner-padding-x: 16px !default;
// $kendo-window-inner-padding-y: 16px !default;

// $kendo-window-buttongroup-padding-x: $actions-padding-x !default;
// $kendo-window-buttongroup-padding-y: $actions-padding-y !default;
// $kendo-window-buttongroup-border-width: 1px !default;

// $kendo-window-bg: $component-bg !default;
// $kendo-window-text: $component-text !default;
$kendo-window-border: $component-border !default;

$kendo-window-titlebar-bg: $white !default;
$kendo-window-titlebar-text: $primary !default;
// $kendo-window-titlebar-border: inherit !default;
// $kendo-window-titlebar-gradient: null !default;

$kendo-window-shadow: 0px 4px 4px rgba(15, 34, 88, 0.06) !default;
// $window-focused-shadow: 1px 1px 7px 1px rgba(0, 0, 0, .3) !default;

// $window-sizes: (
//     sm: 300px,
//     md: 800px,
//     lg: 1200px
// ) !default;
