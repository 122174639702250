.w-position-relative {
  position: relative !important;
}

.w-position-absolute {
  position: absolute !important;
}

.w-position-fixed {
  position: fixed !important;
}

.w-position-sticky {
  position: sticky !important;
}

.w-position-initial {
  position: initial !important;
}

.w-display-block {
  display: block !important;
}

.w-display-inline-block {
  display: inline-block !important;
}

.w-height-100-percent {
  height: 100%;
}