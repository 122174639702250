// Tooltip
$kendo-tooltip-padding-y: map-get($spacing, 1 ) !default;
$kendo-tooltip-padding-x: map-get($spacing, 2 ) !default;
 $tooltip-max-width: 250px !default;
// $kendo-tooltip-border-width: 0px !default;
 $kendo-tooltip-border-radius: $kendo-border-radius-thin !default;

// $kendo-tooltip-font-family: $kendo-font-family !default;
// $kendo-tooltip-font-size: $font-size !default;
// $kendo-tooltip-line-height: 1.25 !default;

// $kendo-tooltip-title-font-size: ($kendo-tooltip-font-size * 1.25) !default;
// $kendo-tooltip-title-line-height: 1 !default;

// $kendo-tooltip-callout-size: 6px !default;

 $kendo-tooltip-bg: $black-75 !default;
 $kendo-tooltip-text: $white !default;
// $kendo-tooltip-border: $kendo-tooltip-bg !default;

// $tooltip-primary-bg: $primary !default;
// $tooltip-primary-text: contrast-wcag( $tooltip-primary-bg ) !default;
// $tooltip-primary-border: $tooltip-primary-bg !default;
// $tooltip-info-bg: $info !default;
// $tooltip-info-text: contrast-wcag( $tooltip-info-bg ) !default;
// $tooltip-info-border: $tooltip-info-bg !default;
// $tooltip-success-bg: $success !default;
// $tooltip-success-text: contrast-wcag( $tooltip-success-bg ) !default;
// $tooltip-success-border: $tooltip-success-bg !default;
// $tooltip-warning-bg: $warning !default;
// $tooltip-warning-text: contrast-wcag( $tooltip-warning-bg ) !default;
// $tooltip-warning-border: $tooltip-warning-bg !default;
// $tooltip-error-bg: $error !default;
// $tooltip-error-text: contrast-wcag( $tooltip-error-bg ) !default;
// $tooltip-error-border: $tooltip-error-bg !default;
