// // Grid
// $grid-line-height: $kendo-line-height !default;

// $grid-padding-x: $table-cell-padding-x !default;
// $grid-padding-y: $table-cell-padding-y !default;
$kendo-grid-padding-x: map-get($spacing, 2)!default;
$kendo-grid-padding-y:  map-get($spacing, 1) !default;
$kendo-grid-header-padding-x: map-get($spacing, 2) !default;
$kendo-grid-header-padding-y: map-get($spacing, 1) !default;
$kendo-grid-header-font-size: $kendo-font-size !default;
$kendo-grid-edit-cell-padding-y: map-get($spacing, thin) !default;
$kendo-grid-edit-cell-padding-x: map-get($spacing, 2)!default;

// $grid-footer-padding-x: $grid-header-padding-x !default;
// $grid-footer-padding-y: $grid-header-padding-y !default;

// $grid-grouping-header-padding-x: $grid-padding-y !default;
// $grid-grouping-header-padding-y: $grid-grouping-header-padding-x !default;

// $grid-filter-cell-padding-x: $grid-padding-y !default;
// $grid-filter-cell-padding-y: $grid-filter-cell-padding-x !default;

// $grid-toolbar-padding-x: $toolbar-padding-x !default;
// $grid-toolbar-padding-y: $toolbar-padding-y !default;
// $grid-toolbar-spacing: $toolbar-spacing !default;
// $grid-toolbar-border-width: $grid-border-width !default;

// $kendo-grid-md-cell-padding-x: map-get($spacing, 2)!default;
// $kendo-grid-md-cell-padding-y: map-get($spacing, 1) !default;
// $kendo-grid-cell-horizontal-border-width: 1px !default;
$kendo-grid-cell-vertical-border-width: 1px !default;

// $kendo-grid-cell-placeholder-line-width: 80% !default;
$kendo-table-header-bg: $base-bg;
$kendo-grid-header-border: $component-border;
// /// Background color of the grid component
// /// @group grid
$kendo-grid-bg: $white !default;
// /// Text color of the grid component
// /// @group grid
$kendo-grid-text: $component-text !default;
// /// Border color of the grid component
// /// @group grid
$kendo-grid-border: $component-border !default;

// /// Background color of grid header
// /// @group grid
$kendo-grid-header-bg: $base-bg !default;
// /// Text color of grid header
// /// @group grid
$kendo-grid-header-text: $component-text !default;
// /// Border color of grid header
// /// @group grid
// $kendo-grid-header-border: $kendo-grid-border !default;
// /// Background gradient of grid header
// /// @group grid
// $kendo-grid-header-gradient: $header-gradient !default;

// /// Background color of grid footer
// /// @group grid
// $grid-group-indicator-border-radius: $kendo-border-radius-md !default;
$kendo-grid-footer-bg: $component-bg !default;
// /// Text color of grid footer
// /// @group grid
// $kendo-grid-footer-text: $kendo-grid-header-text !default;
// /// Border color of grid footer
// /// @group grid
// $kendo-grid-footer-border: $kendo-grid-header-border !default;

// /// Background color of alternating rows in grid
// /// @group grid
$kendo-grid-alt-bg: $grid-alternate !default;
// /// Text color of alternating rows in grid
// /// @group grid
// $kendo-grid-alt-text: null !default;
// /// Border color of alternating rows in grid
// /// @group grid
// $kendo-grid-alt-border: null !default;

// /// Background color of hovered rows in grid
// /// @group grid
$kendo-grid-hovered-bg: $hovered-bg !default;
// /// Text color of hovered rows in grid
// /// @group grid
// $kendo-grid-hovered-text: null !default;
// /// Border color of hovered rows in grid
// /// @group grid
// $kendo-grid-hovered-border: null !default;

// /// Background color of selected rows in grid
// /// @group grid
$kendo-grid-selected-bg: $kendo-grid-bg !default;
// /// Text color of selected rows in grid
// /// @group grid
// $kendo-grid-selected-text: null !default;
// /// Border color of selected rows in grid
// /// @group grid
// $kendo-grid-selected-border: null !default;

$kendo-grid-selected-alt-bg: $grid-alternate !default;
$kendo-grid-selected-hovered-bg: $kendo-grid-bg !default;

// $kendo-grid-grouping-row-bg: $base-bg !default;
$kendo-grid-grouping-row-text: $kendo-grid-text !default;

$kendo-grid-sorted-bg: $grid-sorted !default;
// $kendo-grid-sorting-indicator-text: $primary !default;
// $kendo-grid-sorting-index-font-size: $font-size-sm !default;
// $kendo-grid-sorting-index-height: $icon-size !default;
$kendo-grid-sorting-index-spacing-y: 0 !default;
// $kendo-grid-sorting-index-spacing-x: -$grid-sorting-index-spacing-y !default;


// $grid-focused-shadow: $kendo-list-item-focus-shadow !default;

// $grid-edit-cell-padding-x: $grid-cell-padding-x !default;
// $grid-edit-cell-padding-y: 3px !default;

// $grid-command-cell-button-spacing: map-get( $spacing, 2 ) !default;

// $grid-sticky-bg: $component-bg !default;
// $grid-sticky-text: $grid-text !default;
$kendo-grid-sticky-border: $kendo-grid-border !default;

$kendo-grid-sticky-alt-bg: $kendo-grid-alt-bg !default;

// $kendo-grid-sticky-header-bg: $kendo-grid-header-bg !default;
// $kendo-grid-sticky-header-text: $kendo-grid-header-text !default;
// $kendo-grid-sticky-header-border: $kendo-grid-sticky-border !default;

$kendo-grid-sticky-footer-bg: $kendo-grid-footer-bg !default;
// $grid-sticky-footer-hovered-bg: $grid-hovered-bg !default;

$kendo-grid-sticky-selected-bg: $kendo-grid-bg !default;
$kendo-grid-sticky-selected-alt-bg: $grid-alternate !default;

$kendo-grid-sticky-hovered-bg: $hovered-bg !default;
$kendo-grid-sticky-selected-hovered-bg: $kendo-grid-bg !default;

// $kendo-grid-column-menu-width: 230px !default;

// $kendo-grid-filter-menu-check-all-border-bottom-width: 1px !default;

// $kendo-grid-filter-menu-item-spacing-x: map-get( $spacing, 2 ) !default;
// $kendo-grid-filter-menu-item-spacing-y: $kendo-grid-filter-menu-item-spacing-x !default;

// $kendo-grid-column-menu-popup-padding-x: null !default;
// $kendo-grid-column-menu-popup-padding-y: null !default;

// $kendo-grid-column-menu-item-padding-x: $kendo-menu-popup-item-padding-x !default;
// $kendo-grid-column-menu-item-padding-y: $kendo-menu-popup-item-padding-y !default;

// $kendo-grid-column-menu-list-item-padding-x: $kendo-list-item-padding-x-md !default;
// $kendo-grid-column-menu-list-item-padding-y: $kendo-list-item-padding-y-md !default;

// $kendo-grid-column-menu-items-wrap-padding-x: 0 !default;
// $kendo-grid-column-menu-items-wrap-padding-y: $padding-y-lg !default;

// $kendo-grid-column-menu-filter-container-padding-x: $padding-x !default;
// $kendo-grid-column-menu-filter-container-padding-y: $padding-y !default;

// $kendo-grid-column-menu-group-header-padding-x: $kendo-menu-popup-item-padding-x-md !default;
// $kendo-grid-column-menu-group-header-padding-y: $kendo-menu-popup-item-padding-y-md !default;

// $kendo-grid-column-menu-group-header-font-size: $font-size-sm !default;
// $kendo-grid-column-menu-group-header-line-height: $kendo-line-height !default;
// $kendo-grid-column-menu-group-header-text-transform: uppercase !default;
// $kendo-grid-column-menu-group-header-border-bottom-width: 1px !default;

// $kendo-grid-column-menu-group-header-bg: null !default;
// $kendo-grid-column-menu-group-header-text: $popup-text !default;
// $kendo-grid-column-menu-group-header-border: $popup-border !default;

// $kendo-grid-drag-cell-width: ($icon-size * 2.25) !default;

$kendo-table-footer-bg: $component-text-white;

// Custom variables

$grid-wrapper-border-radius: 4px;
$grid-wrapper-border: rgba($component-text, 0.16);
$grid-sticky-right-shadow: drop-shadow(8px 4px 8px rgba(15, 34, 88, 0.04)) drop-shadow(8px 4px 8px rgba(15, 34, 88, 0.08));
$grid-sticky-left-shadow: drop-shadow(-8px 4px 8px rgba(15, 34, 88, 0.04)) drop-shadow(-8px 4px 8px rgba(15, 34, 88, 0.08));
$grid-readonly-bg: rgba($purple, 0.08);

// For column groups
$odd-group-header-bg: $primary;
$odd-group-header-text: $white;
$odd-group-cell-bg: rgba($purple, 0.08);
$even-group-header-bg: $warning;
$even-group-header-text: $component-text;
$even-group-cell-bg:  rgba($purple, 0.08);