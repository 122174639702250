// Typography

/// Global typography styles for the Kendo Default theme.
/// @group typography
$kendo-enable-typography: true !default;

/// Base font size across all components.
/// @group typography
$kendo-font-size: 14px !default;
$kendo-font-size-xs: 12px !default;
$kendo-font-size-sm: $kendo-font-size !default;
$kendo-font-size-md: $kendo-font-size !default;
$kendo-font-size-lg: 16px !default;
$font-size-sxl: 18px;
$kendo-font-size-xl: 20px !default;
$font-size-thin : 10px !default;

// Font sizes


/// Font family across all components.
/// @group typography
$kendo-font-family: 'Helvetica' !default;

/// Font family for monospaced text. Used for styling the code.
/// @group typography
$kendo-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Roboto Mono", "Ubuntu Mono", "Lucida Console", "Courier New", monospace !default;

/// Line height used along with $font-size.
/// @group typography
$kendo-line-height: 1.4286 !default;
$kendo-line-height-xs: 1 !default;
$kendo-line-height-sm: $kendo-line-height !default;
$kendo-line-height-md: $kendo-line-height !default;
$kendo-line-height-lg: 1.5 !default;
$kendo-line-height-em: calc( #{$kendo-line-height} * 1em ) !default;

// $kendo-line-height: (20 / 14) !default;
// $kendo-line-height-xs: 1 !default;
// $kendo-line-height-sm: 1.25 !default;
// $kendo-line-height-md: $kendo-line-height !default;
// $kendo-line-height-lg: 1.5 !default;
// $kendo-line-height-em: calc( #{$kendo-line-height} * 1em ) !default;

// Font weight
$kendo-font-weight-light: 300 !default;
$kendo-font-weight-normal: 400 !default;
$kendo-font-weight-bold: 700 !default;


// Letter Spacing
$kendo-letter-spacing: null !default;


// Headings
$kendo-h1-font-size: 28px !default;
$kendo-h2-font-size: 25px !default;
$kendo-h3-font-size: 22px !default;
$kendo-h4-font-size: 20px !default;
$kendo-h5-font-size: 18px !default;
$kendo-h6-font-size: 16px !default;

// $kendo-h1-font-size: ($font-size * 3) !default;
// $kendo-h2-font-size: 34px !default;
// $kendo-h3-font-size: ($font-size * 2) !default;
// $kendo-h4-font-size: 22px !default;
// $kendo-h5-font-size: $font-size-lg !default;
// $kendo-h6-font-size: $font-size !default;

$kendo-h1-font-family: $kendo-font-family !default;
$kendo-h2-font-family: $kendo-font-family !default;
$kendo-h3-font-family: $kendo-font-family !default;
$kendo-h4-font-family: $kendo-font-family !default;
$kendo-h5-font-family: $kendo-font-family !default;
$kendo-h6-font-family: $kendo-font-family !default;

$kendo-h1-line-height: 28px !default;
$kendo-h2-line-height: 28px !default;
$kendo-h3-line-height: 28px !default;
$kendo-h4-line-height: 24px !default;
$kendo-h5-line-height: 24px !default;
$kendo-h6-line-height: 20px !default;

// $kendo-h1-line-height: 54px !default;
// $kendo-h2-line-height: 46px !default;
// $kendo-h3-line-height: 42px !default;
// $kendo-h4-line-height: 32px !default;
// $kendo-h5-line-height: 26px !default;
// $kendo-h6-line-height: 20px !default;

$kendo-h1-font-weight: $kendo-font-weight-normal !default;
$kendo-h1-font-weight-bold: $kendo-font-weight-bold !default;
$kendo-h2-font-weight: $kendo-font-weight-normal !default;
$kendo-h3-font-weight: $kendo-font-weight-bold !default;
$kendo-h4-font-weight: $kendo-font-weight-bold !default;
$kendo-h5-font-weight: $kendo-font-weight-normal !default;
$kendo-h6-font-weight: $kendo-font-weight-normal !default;

// $kendo-h1-font-weight: $kendo-font-weight-normal !default;
// $kendo-h2-font-weight: $kendo-font-weight-normal !default;
// $kendo-h3-font-weight: $kendo-font-weight-normal !default;
// $kendo-h4-font-weight: $kendo-font-weight-bold !default;
// $kendo-h5-font-weight: $kendo-font-weight-bold !default;
// $kendo-h6-font-weight: $kendo-font-weight-bold !default;

$kendo-h1-letter-spacing: null !default;
$kendo-h2-letter-spacing: null !default;
$kendo-h3-letter-spacing: null !default;
$kendo-h4-letter-spacing: null !default;
$kendo-h5-letter-spacing: null !default;
$kendo-h6-letter-spacing: null !default;

$kendo-h1-margin: 0 0 $kendo-font-size !default;
$kendo-h2-margin: 0 0 $kendo-font-size !default;
$kendo-h3-margin: 0 0 $kendo-font-size !default;
$kendo-h4-margin: 0 0 $kendo-font-size !default;
$kendo-h5-margin: 0 0 $kendo-font-size !default;
$kendo-h6-margin: 0 0 $kendo-font-size !default;


// Paragraph
$kendo-paragraph-margin: 0 0 $kendo-font-size !default;
$kendo-paragraph-font-size: $kendo-font-size !default;
$kendo-paragraph-font-family: $kendo-font-family !default;
$kendo-paragraph-line-height: 20px !default;
$kendo-paragraph-font-weight: $kendo-font-weight-normal !default;
$kendo-paragraph-letter-spacing: null !default;


// Code
$kendo-code-font-size: $kendo-font-size !default;
$kendo-code-font-family: $kendo-font-family-monospace !default;
$kendo-code-line-height: 20px !default;
$kendo-code-font-weight: $kendo-font-weight-normal !default;
$kendo-code-letter-spacing: null !default;

$kendo-code-padding-x: 4px !default;
$kendo-code-padding-y: 0px !default;
$kendo-pre-padding-x: 24px !default;
$kendo-pre-padding-y: 16px !default;
$kendo-code-border-width: 1px !default;

$kendo-code-bg: $base-bg !default;
$kendo-code-text: $component-text !default;
$kendo-code-border: $component-border !default;

// Link
$kendo-link-text: $primary !default;
$kendo-link-hover-text: $kendo-color-primary-darker !default; 


// Display
$kendo-display1-font-size: 45px !default;
$kendo-display2-font-size: 40px !default;
$kendo-display3-font-size: 36px !default;
$kendo-display4-font-size: 32px !default;
$kendo-display5-font-size: 28px;
// $kendo-display1-font-size: ($kendo-font-size * 6) !default;
// $kendo-display2-font-size: ($kendo-font-size * 5) !default;
// $kendo-display3-font-size: ($kendo-font-size * 4) !default;
// $kendo-display4-font-size: ($kendo-font-size * 3) !default;



$kendo-display1-font-family: $kendo-font-family !default;
$kendo-display2-font-family: $kendo-font-family !default;
$kendo-display3-font-family: $kendo-font-family !default;
$kendo-display4-font-family: $kendo-font-family !default;

$kendo-display1-line-height: 56px !default;
$kendo-display2-line-height: 48px !default;
$kendo-display3-line-height: 44px !default;
$kendo-display4-line-height: 40px !default;
// $kendo-display1-line-height: 1.2 !default;
// $kendo-display2-line-height: 1.2 !default;
// $kendo-display3-line-height: 1.2 !default;
// $kendo-display4-line-height: 1.2 !default;

$kendo-display1-font-weight: $kendo-font-weight-light !default;
$kendo-display2-font-weight: $kendo-font-weight-light !default;
$kendo-display3-font-weight: $kendo-font-weight-light !default;
$kendo-display4-font-weight: $kendo-font-weight-light !default;

$kendo-display1-letter-spacing: null !default;
$kendo-display2-letter-spacing: null !default;
$kendo-display3-letter-spacing: null !default;
$kendo-display4-letter-spacing: null !default;

$font-style-italic : italic;