// // kendo-upload
$kendo-upload-border-width: 1px !default;
// $kendo-upload-font-family: $kendo-font-family !default;
// $kendo-upload-font-size: $kendo-font-size !default;
// $kendo-upload-line-height: $kendo-line-height !default;
// $kendo-upload-max-height: 300px !default;

$kendo-upload-bg: $component-bg !default;
$kendo-upload-text: $component-text !default;
// $kendo-upload-border: $component-bg !default;

// $kendo-upload-dropzone-padding-x: map-get( $spacing, 2 ) !default;
// $kendo-upload-dropzone-padding-y: map-get( $spacing, 2 ) !default;
$kendo-upload-dropzone-bg: $component-bg !default;
$kendo-upload-dropzone-text: $component-text  !default;
// $kendo-upload-dropzone-border: $kendo-upload-border !default;
$kendo-upload-dropzone-hover-bg: $hovered-bg !default;

$kendo-upload-status-text: $secondary !default;
// $kendo-upload-status-text-opacity: null !default;

// $kendo-upload-item-padding-x: map-get( $spacing, 2 ) !default;
// $kendo-upload-item-padding-y: map-get( $spacing, 2 ) !default;

// $kendo-upload-multiple-items-spacing: 12px !default;

// $kendo-upload-validation-font-size: 11px !default;
// $kendo-upload-icon-spacing: $icon-spacing !default;
$kendo-upload-icon-color: $subtle-text !default;

// $kendo-upload-item-image-width: 30px !default;
// $kendo-upload-item-image-height: 30px !default;
// $kendo-upload-item-image-border: 0px !default; // TODO: remove

// $kendo-upload-group-icon-size: $icon-size-lg !default;

// $kendo-upload-progress-thickness: 2px !default;
$kendo-upload-progress-bg: $info !default;
$kendo-upload-progress-text: $component-text !default;

$kendo-upload-success-bg: $success !default;
$kendo-upload-success-text: $success !default;

$kendo-upload-error-bg: $error !default;
$kendo-upload-error-text: $error !default;
$kendo-upload-error-border: $error !default;

$kendo-upload-focus-shadow: 0 0 0 2px rgba(0, 0, 0, .13) !default;

$kendo-upload-border-radius:map-get($spacing,1);