// Expansion panel
// $kendo-expander-spacing-y: $padding-y * 2 !default;
// $kendo-expander-font-family: $kendo-font-family !default;
// $kendo-expander-font-size: $kendo-font-size !default;
// $kendo-expander-line-height: $kendo-line-height !default;
// $kendo-expander-border-width: 1px !default;

$kendo-expander-header-padding-x: 0 !default;
$kendo-expander-header-padding-y: 0 !default;

// $kendo-expander-indicator-margin-x: $padding-x * 1.5 !default;

$kendo-expander-bg: transparent !default;
$kendo-expander-text: $component-text !default;
$kendo-expander-border: transparent !default;

$kendo-expander-focus-shadow: none !default;

// $kendo-expander-header-bg: transparent !default;
$kendo-expander-header-text: $kendo-expander-text !default;
// $kendo-expander-header-border: null !default;

$kendo-expander-header-hover-bg: none !default;

// $kendo-expander-header-focused-bg: null !default;
// $kendo-expander-header-focused-shadow: $kendo-list-item-focus-shadow !default;

// $kendo-expander-title-text: $primary !default;

// $kendo-expander-header-sub-title-text: $subtle-text !default;

$kendo-expander-content-padding-x: 0 !default;
$kendo-expander-content-padding-y: 0 !default;
