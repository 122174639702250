.k-stack-layout-primary {
    background-color: rgba($stack-layout-bg-primary, $stack-layout-bg-opacity-primary);
    border-radius: $stack-layout-border-radius-primary;
    border: $stack-layout-border-primary;
    box-shadow: $stack-layout-box-shadow-primary;

    &.border-radius-xl {
        border-radius: map-get($spacing, 3);
    }

    &.no-shadow {
        box-shadow: none;
    }
}
