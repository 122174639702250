.nav-bar-tree-view {
  .k-treeview {
    color: $kendo-treeview-text
  }
  .k-treeview > ul {
    & > li > div .k-treeview-leaf-text {
     font-weight: $kendo-font-weight-normal;
    }
  }
}

.k-treeview > ul {
  & > li > div .k-treeview-leaf-text {
   font-weight: $kendo-font-weight-bold;
  }
}

.k-treeview {
  color: $component-text
}
.k-treeview-leaf {
  padding-top: map-get($spacing, 2) !important;
  padding-bottom: map-get($spacing, 2) !important;

  &:hover, &.k-hover {
    color: $kendo-treeview-item-hover-text;
    background: $kendo-treeview-item-hover-bg;
  }
  &:focus, &.k-focus {
    box-shadow:$kendo-treeview-item-focus-shadow;
  }
  &.k-selected {
    color: $kendo-treeview-item-selected-text;
    background-color:  $kendo-treeview-item-selected-bg;
    font-weight: $kendo-font-weight-normal;
  }
}

kendo-treeview:not(.secondary) {
  .k-treeview-leaf{
    width: $treeview-selected-button-width;
  .k-treeview-leaf-text{
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      white-space: initial;
    }
  }
    &.k-selected{
      width: $treeview-selected-button-width;
    }
  }
}

.k-treeview.secondary {
  color: $kendo-treeview-text-secondary;
  .k-treeview-item {
    &[aria-checked=true] {
      .k-treeview-leaf {
        color: $kendo-treeview-item-selected-text;
        background-color: $kendo-treeview-item-selected-bg;
      }
    }
    &[aria-checked=false] {
      .k-treeview-leaf {
        color: $kendo-treeview-text-secondary;
        background-color: #FFFFFF;
      }
    }
  }
  .k-treeview-leaf {
    &:hover, &.k-hover {
      color: $kendo-treeview-item-hover-text-secondary;
      background: $kendo-treeview-item-hover-bg-secondary;
    }
    &:focus, &.k-focus {
      box-shadow:$kendo-treeview-item-focus-shadow;
    }
    &.k-selected {
      color: $kendo-treeview-item-selected-text;
      background-color:  $kendo-treeview-item-selected-bg;
    }
    &[aria-checked=true] {
      color: $kendo-treeview-item-selected-text;
      background-color:  $kendo-treeview-item-selected-bg;
  }
  }
}

kendo-treeview.secondary {
  .k-treeview-leaf{
    .k-treeview-leaf-text{
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        margin-top: calc(map-get($spacing, 1) / 2);
        white-space: initial;
      }
    }
  }
}