// // Message box
// $kendo-message-box-margin: 0 0 $spacer 0 !default;
// $kendo-message-box-padding-x: $padding-x !default;
// $kendo-message-box-padding-y: $padding-y !default;
// $kendo-message-box-border-width: 0 0 0 4px !default;

// $kendo-message-box-font-size: $kendo-font-size !default;
// $kendo-message-box-line-height: $kendo-line-height !default;

// $kendo-message-box-bg-level: -10 !default;
// $kendo-message-box-text-level: 6 !default;
// $kendo-message-box-border-level: -9 !default;

// $kendo-message-box-link-font-style: italic !default;
// $kendo-message-box-kendo-link-text-decoration: underline !default;
$error-msg-box-background-color: rgba(215, 22, 22, 0.08);
$error-msg-box-border: 1px solid rgba(215, 22, 22, 0.5);
$error-msg-box-width: 660px;

$notify-msg-box-background-color: rgba(254, 216, 177, 0.08);
$notify-msg-box-border: 1px solid rgba(255, 140, 0, 0.5);
$notify-msg-box-width: 660px;