    // Listview 
    .k-listview {
        .k-listview-content {
            .k-listview-item {
                .k-selected{
                    color:$kendo-listview-item-selected-text;
                    background-color: $kendo-listview-item-selected-bg;
                    border:$kendo-listview-item-selected-border
                }
                &.k-focus{
                    color:$kendo-listview-item-focus-text;
                    background-color: $kendo-listview-item-focus-bg;
                    border:$kendo-listview-item-focus-border;
                }
            }
        }
        &.striped {
            .k-listview-content {
                .k-listview-item {
                    &:nth-child(even){
                        background-color: $listview-item-bg;
                    }
                }
            }
        }
        &.bordered-items .k-listview-content .k-listview-item {
            &:not(:last-child) {
                border-bottom: 1px solid $component-border;
            }
        }
    }

    .k-listview {
        border-radius: $kendo-border-radius-md;
        
        &.card {
            border-radius: map-get($spacing, 1);
            box-shadow: 0px 4px 16px 0px rgba(11, 30, 37, 0.08), 0px 4px 8px 0px rgba(11, 30, 37, 0.04);
        }
    }