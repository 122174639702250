@import "../../common/core/mixins/base-mixin.scss";
/**
    Import variables
*/
@import "./variables/index.scss";

/**
    Import the Kendo Default Theme
*/
@import "~@progress/kendo-theme-default/dist/all.scss";
// @import "~@progress/kendo-theme-default/dist/default-ocean-blue";


/**
    Import overrides
*/

@import "./overrides/index.scss";