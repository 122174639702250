.confirm-dialog-wrapper {
  .k-dialog-titlebar {
    @extend .w-px-4;
    @extend .w-py-2;
  }
  .k-dialog-title {
    @extend .w-base-text-slight-large-bold;
  }
}

.custom-dialog-content {
  .k-dialog-titlebar, .k-dialog-buttongroup {
    border-color: $component-border;
  }
  .k-window-content {
    @extend .w-p-0;
    & > * {
      height: 100%;
      display: block;
      williams-ui-platform-dialog-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .dialog-content {
          flex-grow: 1;
          overflow-y: auto;
        }
      }
    }
  }
}