// Tabstrip
$kendo-tabstrip-wrapper-padding-x: 0px !default;
$kendo-tabstrip-wrapper-padding-y: 0px !default;
$kendo-tabstrip-wrapper-border-width: 0px !default;

$kendo-tabstrip-font-family: $kendo-font-family !default;
$kendo-tabstrip-font-size: $kendo-font-size !default;
$tabstrip-font-weight: $kendo-font-weight-bold !default;
$kendo-tabstrip-line-height: $kendo-line-height !default;
$kendo-tabstrip-border-width: 1px !default;

$kendo-tabstrip-wrapper-bg: null !default;
$kendo-tabstrip-wrapper-text: null !default;
$kendo-tabstrip-wrapper-border: null !default;

/// Background color of tabstrip component
/// @group tabstrip
$kendo-tabstrip-bg: null !default;
/// Text color of tabstrip component
/// @group tabstrip
$kendo-tabstrip-text: $component-text !default;

/// Border color of tabstrip component
/// @group tabstrip
$kendo-tabstrip-border: $component-border !default;

/// Horizontal padding of tabs
/// @group tabstrip
//$kendo-tabstrip-item-padding-x: $nav-item-padding-x !default;
/// Vertical padding of tabs
/// @group tabstrip
//$kendo-tabstrip-item-padding-y: $nav-item-padding-y !default;
/// Width of border around tabs
/// @group tabstrip
$kendo-tabstrip-item-border-width: 1px !default;
/// Border radius of tabs
/// @group tabstrip
$kendo-tabstrip-item-border-radius: $kendo-border-radius-md !default;
/// Spacing between tabs
/// @group tabstrip
//$kendo-tabstrip-item-gap: $nav-item-margin !default;

/// Background color of tabs
/// @group tabstrip
$kendo-tabstrip-item-bg: null !default;
/// Text color of tabs
/// @group tabstrip
$kendo-tabstrip-item-text: $subtle-text !default;
$kendo-tabstrip-item-hover-text: $kendo-link-text !default;
$kendo-tabstrip-item-selected-text: $kendo-link-text !default;
/// Border color of tabs
/// @group tabstrip
$kendo-tabstrip-item-border: 2px solid $kendo-tabstrip-item-text !default;
/// Background gradient of tabs
/// @group tabstrip
$kendo-tabstrip-item-gradient: null !default;

/// Background color of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-bg: null !default;
/// Text color of hovered tabs
/// @group tabstrip
//$kendo-tabstrip-item-hover-text: $kendo-link-hover-text !default;
/// Border color of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-border: 2px solid $kendo-tabstrip-item-hover-text !important !default;
/// Background gradient of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-gradient: null !default;

/// Background color of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-bg: $component-bg !default;
/// Text color of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-text: $component-text !default;
/// Border color of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-border: 2px solid $kendo-tabstrip-item-hover-text !important !default;
/// Border radius of tabs
/// @group tabstrip
$tabstrip-item-selected-border-radius: $kendo-border-radius-thin $kendo-border-radius-thin 0px 0px !default;
/// Background gradient of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-gradient: null !default;

$kendo-tabstrip-item-focus-shadow: null !important !default;

$kendo-tabstrip-item-dragging-shadow: 0px 3px 4px rgba(0, 0, 0, .15) !default;

$kendo-tabstrip-indicator-size: null !default;
$kendo-tabstrip-indicator-color: null !default;

/// Horizontal padding of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-padding-x: map-get( $spacing, 0 ) !default;
/// Vertical padding of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-padding-y: map-get( $spacing, 0 ) !default;
/// Width of border around tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-border-width: 1px !default;
$tabstrip-content-border-none: none !default;

/// Background color of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-bg: transparent !default;
/// Text color of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-text: $component-text !default;
/// Border color of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-border: null !default;
/// Border color of tabstrip focused content
/// @group tabstrip
$kendo-tabstrip-content-focus-border: $component-text !default;
