.k-switch-off,
.k-switch-on {
  &:focus,
  &.k-focus {
    .k-switch-track {
      box-shadow: $switch-track-focus-shadow;
    }
  }
}

.switch-grid-readonly {
  border: 1px solid $switch-grid-readonly-border;
  background: $switch-grid-readonly-off-bg;
  color: $subtle-text;
  border-radius: 100%;
  font-weight: $kendo-font-weight-bold;

  &.is-checked {
    background: $switch-grid-readonly-on-bg;
  }

}

@each $size, $size-props in $kendo-switch-sizes {
  $_border-radius: map-get( $size-props, track-height );

  .switch-grid-readonly {
    &.k-switch-#{$size} {
      border-radius: $_border-radius;
    }
  }
}