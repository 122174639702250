// Width of the border around the button.
// $kendo-button-border-width: 1px
// Border radius of the button.
// $kendo-button-border-radius
// Horizontal padding of the button. default - k-map-get( $kendo-spacing, 2 ) - 8px
// $kendo-button-padding-x
// Vertical padding of the button. default - k-map-get( $kendo-spacing, 1 ) - 4px
// $kendo-button-padding-y
// Font family of the button.
// $kendo-button-font-family
// Font sizes of the button.
// $kendo-button-font-size
// Line heights used along with $kendo-font-size.
// $kendo-button-line-height


// Light mode variables
$kendo-button-light-bg: #ffffff !default;
$kendo-button-light-color: #000000 !default;
$kendo-button-light-border: rgba(0, 0, 0, 0.1) !default;
$box-shadow-button:inset 0 -3px 0 0 #0079C1 !default;
$box-shadow-button-focused:inset 0 -4.5rem 0 0 #0079C1 !default;
$button-transition: 200ms ease-in-out !default;

// Dark mode variables
$kendo-button-dark-bg: #333333 !default;
$kendo-button-dark-color: #ffffff !default;
$kendo-button-dark-border: rgba(255, 255, 255, 0.1) !default;

$box-border-radius: 0px !default;
$color-text:#0B1E25 !default;
$color-white:white !default;
$border_value: rgba(0, 0, 0, 0.08) !default;
$box_shadow_val:0px 0px 0px 2px rgba(22, 155, 215, 0.3) !default;
// Theme colors map for the button.
$kendo-button-theme-colors: (
  "primary": $primary,
  "secondary": $secondary_button_bg,
  "tertiary": $tertiary,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "error": $error,
  "dark": $dark,
  "light": $light,
  "inverse": $inverse,
  "base": $color-text,
  
) !default;
//-----------------------solid-----------------------------------------------------
$kendo-button-secondary-styles:(
  "color": $color-text,
  "border": $border_value
) !default;

$kendo-button-primary-hover-styles:(
  "bg": (linear-gradient(0deg, rgba(15, 34, 88, 0.16), rgba(15, 34, 88, 0.16)) #0079C1)

) !default;
$kendo-button-secondary-hover-styles:(
  "color": $color-text,
  "bg": (linear-gradient(0deg, rgba(15, 34, 88, 0.04), rgba(15, 34, 88, 0.04)), #FAFBFC),
  "border":$border_value
) !default;

$kendo-button-light-styles: (
        "bg": #ffffff,
        "color": $color-text,
        "border": $color-text,
        "border-radius": $box-border-radius,
        "box-shadow": $box-shadow-button,
        "transition": $button-transition,
) !default;
$kendo-button-discovery-styles: (
    "bg": #ffffff,
    "color": #0b1e25,
    "border": transparent,
    "border-radius": $box-border-radius,
    ) !default;

$kendo-button-light-hover-styles:(
    "bg": #ffffff,
    "color": $color-white,
    "border": $color-text,
    "box-shadow": $box-shadow-button-focused,
) !default;

$kendo-button-dark-hover-styles:(
        "bg": $color-text,
        "color": $color-white,
        "border": $color-white,
        "box-shadow": $box-shadow-button-focused,
) !default;

$kendo-button-dark-styles: (
        "bg": $color-text,
        "color": $color-white,
        "border": $color-white,
        "border-radius": $box-border-radius,
        "box-shadow": $box-shadow-button,
        "transition": $button-transition,
) !default;

$kendo-button-styles:(
"light":$kendo-button-light-styles,
"dark":$kendo-button-dark-styles,
"secondary":$kendo-button-secondary-styles,
) !default;

$kendo-button-hover-styles:(
"light":$kendo-button-light-hover-styles,
"dark":$kendo-button-dark-hover-styles,
"primary": $kendo-button-primary-hover-styles,
"secondary":$kendo-button-secondary-hover-styles
) !default;

$kendo-button-secondary-focused-styles:(
  "box-shadow": $box_shadow_val
) !default;
$kendo-button-focused-styles:(
"secondary":$kendo-button-secondary-focused-styles
) !default;

//---------------------------outline-------------------------------------------------
$kendo-button-outline-primary-styles:(
"bg": $color-white

)!default;
$kendo-button-outline-secondary-styles:(
"border": $color-text,
"color":$color-text
) !default;

$kendo-button-outline-styles:(
  "primary":$kendo-button-outline-primary-styles,
"secondary":$kendo-button-outline-secondary-styles
) !default;

$kendo-button-outline-sec-hover-styles:(
  "color":$color-white,
  "bg":$color-text,
) !default;

$kendo-button-outline-hover-styles:(
"secondary":$kendo-button-outline-sec-hover-styles
) !default;

$kendo-button-outline-secondary-focused-styles:(
  "box-shadow": $box_shadow_val
) !default;
$kendo-button-outline-focused-styles:(
"secondary":$kendo-button-outline-secondary-focused-styles
) !default; 
//------------------------------------flat---------------------------
$kendo-button-flat-secondary-styles:(
// "border": $color-text,
"color":$color-text
) !default;

$kendo-button-flat-styles:(
"secondary":$kendo-button-flat-secondary-styles
) !default;



// The base background of the button.
$kendo-button-bg: #fafbfc !default;
// The base text color of the button.
$kendo-button-text: $base-text !default;
// The base border color of the button.
$kendo-button-border: rgba(0, 0, 0, 0.08) !default;
// $kendo-button-border-radius: 2px !default;
// The base background gradient of the button.
// $kendo-button-gradient	List
// The base shadow of the button.
// $kendo-button-shadow	Null

// The base background of hovered button.
// $kendo-button-hover-bg: red !default;

// The base text color of hovered button.
// $kendo-button-hover-text
// The base border color of hovered button.
// $kendo-button-hover-border
// The base background gradient of hovered button.
// $kendo-button-hover-gradient
// The base shadow of hovered button.
// $kendo-button-hover-shadow
// The base background color of active button.
// $kendo-button-active-bg
// The base text color of active button.
// $kendo-button-active-text
// The base border color of active button.
// $kendo-button-active-border
// The base background gradient of active button.
// $kendo-button-active-gradient
// The base shadow of active button.
// $kendo-button-active-shadow
// The base background color of selected button.
// $kendo-button-selected-bg
// The text color of selected buttons.
// $kendo-button-selected-text
// $kendo-button-selected-border
// The border color of selected buttons.
// $kendo-button-selected-bg
// $kendo-button-selected-gradient
// $kendo-button-active-gradient
// $kendo-button-selected-shadow
// $kendo-button-active-shadow
// $kendo-button-focus-bg
// $kendo-button-focus-text
// $kendo-button-focus-border
// $kendo-button-focus-gradient
// $kendo-button-focus-shadow
// $kendo-button-disabled-bg
// $kendo-button-disabled-text
// $kendo-button-disabled-border
// $kendo-button-disabled-gradient
// $kendo-button-disabled-shadow
// $kendo-flat-button-hover-opacity
// $kendo-flat-button-focus-opacity
// $kendo-flat-button-active-opacity
// $kendo-flat-button-selected-opacity
// $kendo-flat-button-focus-ring-opacity
// $kendo-button-transition

$material-icon-text-gap: 4px !default;

$button-height-sm:24px !default;
$button-height-md:28px !default;
$button-height-lg:32px !default;

$kendo-button-padding-x-sm: map-get($spacing, 2) !default;
$kendo-button-padding-y-sm: map-get($spacing, hair) !default;
$kendo-button-font-size-sm: $kendo-font-size !default;
$kendo-button-padding-x-md: map-get($spacing, 2) !default;
$kendo-button-padding-y-md: map-get($spacing, 1) - map-get($spacing, hair) !default;
$kendo-button-font-size-md: $kendo-font-size !default;
$kendo-button-padding-x-lg: map-get($spacing, 2) !default;
$kendo-button-padding-y-lg: map-get($spacing, 1) - map-get($spacing, hair) !default;
$kendo-button-font-size-lg: $kendo-font-size-lg !default;