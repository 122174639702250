.k-stepper .k-step-label {
    flex-wrap: nowrap;
}

.k-stepper .k-step-label .k-step-text {
    overflow: unset;
}

.k-stepper .k-step-current .k-step-label {
    box-shadow: $stepper-current-label-box-shadow;

}

.k-stepper .k-step.k-focus .k-step-label:only-child,
.k-stepper .k-step .k-step-link:focus .k-step-label:only-child {
    box-shadow: $stepper-current-label-box-shadow;
}