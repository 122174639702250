.w-base-text-large-bold {
    @include typography($kendo-font-size-lg,null,24px,$kendo-font-weight-bold,null);
}
.w-text-large-normal {
    @include typography($kendo-font-size-lg,null,20px,$kendo-font-weight-normal,null);
}
.w-text-sm-normal {
    @include typography($kendo-font-size-sm,null,20px,$kendo-font-weight-normal,null);
}
.w-h1-bold {
    font-weight: $kendo-h1-font-weight-bold !important;
}
.w-small-text-bold {
    @include typography($kendo-font-size-xs,null,16px,$kendo-font-weight-bold,null);
}
.w-small-text-normal{
    @include typography($kendo-font-size-xs,null,16px,$kendo-font-weight-normal,null);
}

.w-medium-text-normal{
    @include typography($kendo-font-size,null,20px,$kendo-font-weight-normal,null);
}

.w-small-text-normal {
    @include typography($kendo-font-size-xs,null,16px,$kendo-font-weight-normal,null);
}
.w-base-text-slight-large-bold {
    @include typography($font-size-sxl,null,24px,$kendo-font-weight-bold,null);
}
.w-window-title {
    @include typography($font-size-sxl,null,24px,$kendo-font-weight-bold,null);
}
.w-font-italic{
    font-style: $font-style-italic ;
}
.w-font-xs-light{
    @include typography($kendo-font-size-xs,null,16px,$kendo-font-weight-light,null);
}
.w-display-3 {
    @include typography($kendo-display3-font-size, null, $kendo-display2-line-height, $kendo-font-weight-light, null);
}
.w-display-4 {
    @include typography($kendo-display4-font-size,null,40px,$kendo-font-weight-light,null);
}
.w-display-5 {
    @include typography($kendo-display5-font-size,null,32px,$kendo-font-weight-bold,null);
}
.w-hint-text {
    @include typography($kendo-font-size-xs,null,16px,$kendo-font-weight-light,null);
}
.w-base-text {
    @include  typography($kendo-font-size,null,20px,$kendo-font-weight-light,null);
}
.w-base-text-bold {
    @include  typography($kendo-font-size,null,20px,$kendo-font-weight-bold,null);
}
.w-base-pill-badge-medium {
    @include  typography($kendo-font-size - 4,null,12px,$kendo-font-weight-bold,null);
    letter-spacing: 1px;
}
.w-font-weight-normal {
    font-weight: $kendo-font-weight-normal !important;
}

.w-font-weight-bold {
    font-weight: $kendo-font-weight-bold !important;
}

.w-line-height-1 {
    line-height: 1;
}

.w-h4 {
    @extend .k-h4;
}

.placeholder-text-color {
    color: $placeholder-text;
}