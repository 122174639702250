.w-link-button {
  color: $component-text;
  text-decoration: underline !important;
  font-weight: $kendo-font-weight-bold;
  cursor: pointer;
  &.font-normal{
    font-weight: $kendo-font-weight-normal;
  }

  &.color-primary {
    color: $primary;
    text-decoration-color: $primary;
  }
}