    .k-upload {
         @include wFill(
            $kendo-upload-text,
            $kendo-upload-bg,
            $kendo-upload-border
        );
        border-radius:$kendo-upload-border-radius;

        .k-dropzone {
             @include wFill(
                $kendo-upload-dropzone-text,
                $kendo-upload-dropzone-bg,
                $kendo-upload-dropzone-border
            );

            border-radius:$kendo-upload-border-radius;

            &.k-dropzone-hover,
            &.k-dropzone-hovered {
                background-color: $kendo-upload-dropzone-hover-bg;
            }

            .k-dropzone-hint {
                color: $kendo-upload-status-text;
                opacity: $kendo-upload-status-text-opacity;
            }

        }

        .k-upload-files,
        .k-file {
            border-color: $kendo-upload-border;
        }

        .k-file {

            &.k-focus {
                box-shadow: inset $kendo-upload-focus-shadow;
            }

            .k-upload-action.k-focus {
                box-shadow: $kendo-upload-focus-shadow;
            }

        }

        .k-upload-status {
            color: $kendo-upload-status-text;
            opacity: $kendo-upload-status-text-opacity;
        }

        // Upload status: uploading
        .k-file-progress {

            .k-file-name {
                color: $kendo-upload-progress-text;
            }
            .k-progress {
                background-color: $kendo-upload-progress-bg;
            }
        }


        // Upload status: success
        .k-file-success {
            .k-progress {
                background-color: $kendo-upload-success-bg;
            }
        }

        // Upload satus: error
        .k-file-error,
        .k-file-invalid {
            .k-file-validation-message {
                color: $kendo-upload-error-text;
            }
            .k-progress {
                background-color: $kendo-upload-error-bg;
            }
        }

        .k-file-extension-wrapper,
        .k-multiple-files-extension-wrapper,
        .k-file-group-wrapper,
        .k-multiple-files-group-wrapper,
        .k-file-invalid-extension-wrapper,
        .k-multiple-files-invalid-extension-wrapper,
        .k-file-invalid-group-wrapper,
        .k-multiple-files-invalid-group-wrapper {
            color: $kendo-upload-icon-color;
            border-color: $kendo-upload-icon-color;
        }

        // old rendering
        .k-file-invalid-extension-wrapper,
        .k-multiple-files-invalid-extension-wrapper,
        .k-file-error .k-file-extension-wrapper,
        .k-file-invalid-group-wrapper,
        .k-multiple-files-invalid-group-wrapper,
        .k-file-error .k-file-group-wrapper {
            border-color: $kendo-upload-icon-color;
        }

        // old rendering
        .k-file-extension-wrapper::before,
        .k-multiple-files-extension-wrapper::before,
        .k-file-invalid-extension-wrapper::before,
        .k-multiple-files-invalid-extension-wrapper::before,
        .k-file-error .k-file-extension-wrapper::before {
            background-color: $kendo-upload-bg;
            border-color: transparent transparent $kendo-upload-icon-color $kendo-upload-icon-color;
        }

        // old rendering
        .k-multiple-files-extension-wrapper::after,
        .k-multiple-files-invalid-extension-wrapper::after {
            border-top-color: $kendo-upload-icon-color;
            border-left-color: $kendo-upload-icon-color;
        }

        .k-file-size,
        .k-file-validation-message,
        .k-file-information {
            color: $subtle-text;
        }

        .k-multiple-files-wrapper .k-file-information {
            color: $kendo-upload-text;
        }

        // Invalid,
        &.k-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            border-color: $kendo-invalid-border;
        }
    }   