@import "../../../common/core/mixins/base-mixin.scss";

@each $name, $color in $kendo-button-theme-colors {
  @if ($name != "base") {
    $_styles: map-get($kendo-button-styles, $name);
    $_styles: if($_styles, $_styles, ());
    $_hover-styles: map-get($kendo-button-hover-styles, $name);
    $_hover-styles: if($_hover-styles, $_hover-styles, ());
    $_focused-styles: map-get($kendo-button-focused-styles, $name);
    $_focused-styles: if($_focused-styles, $_focused-styles, ());

    $_styles_outline: map-get($kendo-button-outline-styles, $name);
    $_styles_outline: if($_styles_outline, $_styles_outline, ());
    $_outline-hover: map-get($kendo-button-outline-hover-styles, $name);
    $_outline-hover: if($_outline-hover, $_outline-hover, ());
    $_outline-focused: map-get($kendo-button-outline-focused-styles, $name);
    $_outline-focused: if($_outline-focused, $_outline-focused, ());

    $_styles_flat: map-get($kendo-button-flat-styles, $name);
    $_styles_flat: if($_styles_flat, $_styles_flat, ());

    .k-button-solid-#{$name} {
      @include wFill(
        map-get($_styles, "color"),
        map-get($_styles, "bg"),
        map-get($_styles, "border")
      );

      // Hover state
      &:hover,
      &.k-hover {
        @include wFill(
          map-get($_hover-styles, "color"),
          map-get($_hover-styles, "bg"),
          map-get($_hover-styles, "border")
        );
      }

      // Focus state
      &:focus,
      &.k-focus {
        // @if ( $kendo-solid-button-shadow ) {
        //     box-shadow: 0 0 $kendo-solid-button-shadow-blur $kendo-solid-button-shadow-spread rgba( $_button-border, $kendo-solid-button-shadow-opacity );
        // }
        @if ($_focused-styles) {
          box-shadow: map-get($_focused-styles, "box-shadow");
        }
      }

      // Active state
      &:active,
      &.k-active {
        @include fill(
          // $_button-active-text,
          // $_button-active-bg,
          // $_button-active-border
        );
      }

      // Selected
      &.k-selected {
        @include fill(
          // $_button-active-text,
          // $_button-active-bg,
          // $_button-active-border
        );
      }

      // Disabled state
      &:disabled,
      &.k-disabled {
        @include fill(
          // $kendo-button-disabled-text,
          // $kendo-button-disabled-bg,
          // $kendo-button-disabled-border,
          // $kendo-button-disabled-gradient
        );
        @include box-shadow($kendo-button-disabled-shadow);
      }
    }

    .k-button-outline-#{$name} {
      @include box-shadow(none);
      border-color: if(
        $_styles_outline,
        map-get($_styles_outline, "border"),
        currentColor
      );
      color: if($_styles_outline, map-get($_styles_outline, "color"), $color);
      background-color: if($_styles_outline, map-get($_styles_outline, "bg"), transparent);

      // Hover state
      &:hover,
      &.k-hover {
        @include wFill(
          map-get($_outline-hover, "color"),
          map-get($_outline-hover, "bg"),
          map-get($_outline-hover, "border"),
          map-get($_outline-hover, "gradient")
        );
      }

      // Focus state
      &:focus,
      &.k-focus {
        // @if $kendo-outline-button-shadow {
        //     box-shadow: 0 0 $kendo-outline-button-shadow-blur $kendo-outline-button-shadow-spread rgba( $color, $kendo-outline-button-shadow-opacity );
        // }
        @if ($_focused-styles) {
          box-shadow: map-get($_outline-focused, "box-shadow");
        }
      }

      // Active state
      &:active,
      &.k-active {
        @include wFill(contrast-wcag($color), $color, $color);
      }

      // Selected
      &.k-selected {
        @include wFill(contrast-wcag($color), $color, $color);
      }

      // Disabled state
      &:disabled,
      &.k-disabled {
        color: $kendo-button-disabled-text;
      }
    }

    // Flat button

    .k-button-flat-#{$name} {
      color: if($_styles-flat, map-get($_styles-flat, "color"), $color);

      // Disabled state
      &:disabled,
      &.k-disabled {
        color: $kendo-button-disabled-text;
      }
    }
  }
}

    .k-button:disabled, .k-button.k-disabled {
    opacity: 0.6;
    }

//light new button style
.k-button-light {
  @include wFill(
                  map-get($kendo-button-light-styles, "color"),
                  map-get($kendo-button-light-styles, "bg"),
                  map-get($kendo-button-light-styles, "border"),
                  map-get($kendo-button-light-styles, "gradient"),
                  map-get($kendo-button-light-styles, "box-shadow")
  );
  @include wBorder-radius(
                  map-get($kendo-button-light-styles, "border-radius")
  );
  @include wTransition(
                  map-get($kendo-button-light-styles, "transition"),
  );

  &:hover {
    @include wFill(
                    map-get($kendo-button-light-hover-styles, "color"),
                    map-get($kendo-button-light-hover-styles, "bg"),
                    map-get($kendo-button-light-hover-styles, "border"),
                    map-get($kendo-button-light-hover-styles, "gradient"),
                    map-get($kendo-button-light-hover-styles, "box-shadow"),
    );
  }
}
  //dark new button style
  .k-button-dark {
    @include wFill(
                    map-get($kendo-button-dark-styles, "color"),
                    map-get($kendo-button-dark-styles, "bg"),
                    map-get($kendo-button-dark-styles, "border"),
                    map-get($kendo-button-dark-styles, "gradient"),
                    map-get($kendo-button-dark-styles, "box-shadow"),
    );
    @include wBorder-radius(
                    map-get($kendo-button-dark-styles, "border-radius")
    );
    @include wTransition(
                    map-get($kendo-button-dark-styles, "transition"),
    );

    &:hover {
      @include wFill(
                      map-get($kendo-button-dark-hover-styles, "color"),
                      map-get($kendo-button-dark-hover-styles, "bg"),
                      map-get($kendo-button-dark-hover-styles, "border"),
                      map-get($kendo-button-dark-hover-styles, "gradient"),
                      map-get($kendo-button-dark-hover-styles, "box-shadow"),
      );
    }
    // Custom styling can be added here
  }
.k-button-discovery{
  @include wFill(
                  map-get($kendo-button-discovery-styles, "color"),
                  map-get($kendo-button-discovery-styles, "bg"),
                  map-get($kendo-button-discovery-styles, "border"),
                  map-get($kendo-button-discovery-styles, "gradient"),
                  map-get($kendo-button-discovery-styles, "box-shadow")
  );
  &:hover{
    @include wFill(
                  map-get($kendo-button-discovery-styles, "color"),
                  map-get($kendo-button-discovery-styles, "bg"),
                  map-get($kendo-button-discovery-styles, "border"),
                  map-get($kendo-button-discovery-styles, "gradient"),
                  map-get($kendo-button-discovery-styles, "box-shadow"),
    );
  }
  .k-button-icon{
    display: flex;
  }
}
// @each $name, $color in map-merge( $kendo-button-theme-colors, ( "base": $base-text ) ) {
// @each $name, $color in  $kendo-button-theme-colors  {
//     // $kendo-button-outline-hover-styles
//     $_styles: map-get($kendo-button-outline-styles, $name);
//     $_styles:if($_styles,$_styles,());

//     $_hover-styles: map-get($kendo-button-hover-styles, $name);
//     $_hover-styles:if($_hover-styles,$_hover-styles,());

//     .k-button-outline-#{$name} {
//         @include box-shadow( none );
//         border-color: if($_styles, map-get($_styles, "border"), currentColor);
//         color: if($_styles, map-get($_styles, "color"), $color);
//         background-color: transparent;

//         // Hover state
//         &:hover,
//         &.k-hover {
//             @include wFill(
//                 contrast-wcag( $color ),
//                 $color,
//                 $color
//             );
//         }

//         // Focus state
//         &:focus,
//         &.k-focus {
//             // @if $kendo-outline-button-shadow {
//             //     box-shadow: 0 0 $kendo-outline-button-shadow-blur $kendo-outline-button-shadow-spread rgba( $color, $kendo-outline-button-shadow-opacity );
//             // }
//             @if ( $name== "secondary" ) {
//                 box-shadow: 0px 0px 0px 2px rgba(22, 155, 215, 0.3);
//             }
//         }

//         // Active state
//         &:active,
//         &.k-active {
//             @include wFill(
//                 contrast-wcag( $color ),
//                 $color,
//                 $color
//             );
//         }

//         // Selected
//         &.k-selected {
//             @include wFill(
//                 contrast-wcag( $color ),
//                 $color,
//                 $color
//             );
//         }

//         // Disabled state
//         &:disabled,
//         &.k-disabled {
//             color: $kendo-button-disabled-text;
//         }
//     }
// }


@each $size, $size-props in $kendo-button-sizes {
    $_font-size: map-get($size-props, font-size );
    .k-button-#{$size} {
      .k-button-text {
        display: flex;
        align-items: center;
        gap: $material-icon-text-gap;
      }
  }
}

.k-button {
  font-weight: $kendo-font-weight-bold;
}

.k-picker .k-input-button, .k-input-button {
  border-radius: 0;
  background: $button_bg;
  border-color: none;
}

.btn-icon-only-padding {
  padding-left: map-get($spacing, 1 ) - map-get($spacing, hair );
  padding-right: map-get($spacing, 1 ) - map-get($spacing, hair );
}

.k-button-sm{
  height: $button-height-sm;
  &.icon-only {
    @extend .btn-icon-only-padding;
  }
}

.k-button-md{
  height: $button-height-md;
  &.icon-only {
    @extend .btn-icon-only-padding;
  }
}

.k-button-lg{
  height: $button-height-lg;
  &.icon-only {
    @extend .btn-icon-only-padding;
  }
}

.w-icon-button{
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  &-error{
    border: 1px solid $error;
    color: $error;
    background-color: rgba($error, 0.16);
  }
  &-success{
    border: 1px solid $success;
    color: $success;
    background-color: rgba($success, 0.16);
  }
}
