@each $index, $value in map-merge($spacing, ( 'auto': auto )) {
  .w-pt-#{$index} {
    padding-top: $value;
  }
  .w-pl-#{$index} {
    padding-left: $value;
  }
  .w-pr-#{$index} {
    padding-right: $value;
  }
  .w-pb-#{$index} {
    padding-bottom: $value;
  }
  .w-p-#{$index} {
    padding: $value;
  }
  .w-px-#{$index} {
    padding-left: $value;
    padding-right: $value;
  }
  .w-py-#{$index} {
    padding-top: $value;
    padding-bottom: $value;
  }

  //margins

  .w-mt-#{$index} {
    margin-top: $value;
  }
  .w-ml-#{$index} {
    margin-left: $value;
  }
  .w-mr-#{$index} {
    margin-right: $value;
  }
  .w-mb-#{$index} {
    margin-bottom: $value;
  }
  .w-m-#{$index} {
    margin: $value;
  }
  .w-mx-#{$index} {
    margin-left: $value;
    margin-right: $value;
  }
  .w-my-#{$index} {
    margin-top: $value;
    margin-bottom: $value;
  }
  .w-ml-n#{$index} {
    margin-left: -$value;
  }
}