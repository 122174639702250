.w-bookmarked{
    height: 16px;
    color: $w-book-mark-color;
    z-index: 9999;
}

.w-no-bookmarked{
    height: 16px;
    color: transparent;
    z-index: 9999;
}

