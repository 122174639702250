
@mixin wFill( $color: null, $bg: null, $border: null, $gradient: null, $box-shadow: null ) {
    @include fill(
        $color,
        null,
        $border,
        $gradient
);
   
    @if $bg {
        background: $bg;
    }
    @if $box-shadow {
        box-shadow: $box-shadow;
    }
}


@mixin wBorder-radius( $radius ) {
    border-radius: $radius;
}

@mixin wTransition($transition) {
    transition: $transition;
  }

