
$side-panel-header-title-font-size: 10px;
$side-panel-border-radius: 12px;
$expandable-content-border: 1px solid rgba(15, 34, 88, 0.16);
$expandable-content-box-shadow: 0 4px 8px rgba(15, 34, 88, 0.04), 0 4px 16px rgba(15, 34, 88, 0.08);
$borderless-grid-header-background-color: #e3e3e3;/*#f5f6f8;*/
$side-panel-text-padding: 8px 12px;
$side-panel-text-height: 32px;
$text-font-size: $kendo-font-size;
$radio-button-bg: #0097e9;
$radio-button-border: 1px solid rgba(15, 34, 88, 0.8);
$grid-border-color: $grid-wrapper-border;

.williams-grid .k-command-cell .k-icon-button {
  border: none;
  padding: 0;
}

.p-0{
  padding: 0;
}

.buttons {
  display: flex;
  gap: 12px;
}
.panel-header {
  display: flex;
  align-items: center;
  height: 28px;
  padding-left: 34px;

  p {
    margin: 0;
  }
}
.side-panel-layout-container{
  display: flex;
  height: 100%;
  flex-direction: column;
}
.side-panel-layout{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0;
  gap: 8px;
  .record-status{
    font-style: italic;
    font-size: $text-font-size;
  }
  .text-area-with-error{
    .custom-styling{
      flex-flow: column;
    }
  }
  .checkbox-with-error{
    position: relative;
    .formerror-wrapper{
      height: 20px;
    }
    williams-ui-platform-formerror{
      position: absolute;
      bottom: 0;
    }
  }
}
.side-panel-layout-item{
  padding: 0;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}
.side-panel-layout-item .k-expander-header{
  padding: 5px 5px;
}
.side-panel-layout kendo-expansionpanel{
  font-size: $text-font-size;
}

.side-panel-layout-item .k-expander-content {
  padding: 0 12px;
}

.side-panel-layout kendo-expansionpanel .k-expander-title {
  font-weight: $kendo-font-weight-bold;
  color: $primary;
}
.text-content{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 12px 0 0;
  gap: 8px;
  div.k-label{
    font-size: $text-font-size;
  }
  .d-inline-flex{
    gap: 8px;
  }
}
.w-table{
  display: table;
}
.w-table-row{
  display: table-row;
}
.w-table-cell{
  display: table-cell;
  padding: 4px;
}
.grid-view-expandable-content {
  border: $expandable-content-border;
  box-shadow: $expandable-content-box-shadow;
  border-radius: $side-panel-border-radius;
}

williams-ui-platform-button-group.borderless .button-group-layout button,
williams-ui-platform-tab-list.borderless .tabs button{
  border: none;
  border-image: none;
  border-radius: 0;
  color: $subtle-text;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

williams-ui-platform-button-group.borderless .button-group-layout button.k-selected,
williams-ui-platform-tab-list.borderless .tabs button.k-selected{
  color: $kendo-link-text;
  background-color: $white;
  border-bottom-color: $kendo-link-text;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

williams-ui-platform-button-group.borderless .button-group-layout button:hover,
williams-ui-platform-button-group.borderless .button-group-layout button.k-selected:hover{
  background-color: $white;
  color: $kendo-link-text;
  border-bottom-color: $white;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
williams-ui-platform-tab-list.borderless .tabs button:hover,
williams-ui-platform-tab-list.borderless .tabs button.k-selected:hover{
  background: none;
  box-shadow: none;
  color: $kendo-link-text;
}

williams-ui-platform-button-group.borderless .button-group-layout button.k-selected:hover,
williams-ui-platform-tab-list.borderless .tabs button.k-selected:hover {
  border-bottom-color: $kendo-link-text;
}

williams-ui-platform-tab-list.borderless .tabs button.k-selected{
  border-radius: 0;
  background: white;
  font-weight: $kendo-font-weight-bold;
}
williams-ui-platform-tab-list.borderless .tabs button{
  border-radius: 0;
  background: none;
  font-weight: $kendo-font-weight-bold;
}

williams-ui-platform-button-group{
  button {
    font-weight: $kendo-font-weight-bold;
    font-size: $text-font-size
  }

  .k-selected {
    box-shadow: $tab-box-shadow;
    -webkit-box-shadow: $tab-box-shadow;
    -moz-box-shadow: $tab-box-shadow;
  }
}

williams-ui-platform-tab-list.borderless .tabs button.k-button-solid-base:focus,
williams-ui-platform-tab-list.borderless .tabs button.k-button-solid-base.k-focus,
williams-ui-platform-button-group.borderless .button-group-layout button.k-button-outline-primary.k-focus,
williams-ui-platform-button-group.borderless .button-group-layout button.k-button-outline-primary:focus{
  box-shadow: none;
}

williams-ui-platform-grid-view-expansion-panel{
  width: 100%;
  .borderless-kendo-grid {
    kendo-grid{
      border: none;
      box-shadow: none;
      border-radius: $side-panel-border-radius;
      thead tr{
        background-color: $borderless-grid-header-background-color;
        font-weight: $kendo-font-weight-bold;
        font-size: $side-panel-header-title-font-size;
      }
    }
  }
  .header-border{
    border-style: solid;
    border-width: 1px 0;
    border-color: $grid-border-color;
  }
  .header-text{
    background-color: $borderless-grid-header-background-color;
    font-weight: $kendo-font-weight-bold;
    font-size: $text-font-size;
    font-family: $kendo-font-family;
    padding: $side-panel-text-padding;
  }
  .text{
    background-color: $white;
    font-size: $text-font-size;
    font-family: $kendo-font-family;
    padding: $side-panel-text-padding;
  }
  .border-radius{
    border-radius: $side-panel-border-radius;;
  }
  .summary-header {
    display: flex;
    gap: 4px;
    height: 28px;
    align-items: center;
    cursor: pointer;
    p {
      font-size: $side-panel-header-title-font-size;
      line-height: $text-font-size;
      font-weight: $kendo-font-weight-bold;
      margin: 0;
    }

    .arrow-icon {
      width: 16px;
      height: 16px;
    }
  }
  .wrap {
    display: flex;
    flex-direction: column;
  }
}

div.border-bottom{
  border-bottom: 1px solid rgba(16, 24, 32, 0.3);
}

.tooltip-button {
  width: 16px;
  height: 16px;
  padding: 0;
}

.combobox-wrapper {
  height: 30px;
  margin-top: 4px;
}


.k-radio {
  border: $radio-button-border;
}

.k-radio:checked, .k-radio.k-checked {
  background-color: $radio-button-bg;

  border-color: $radio-button-bg;
}
/*column menu check box disappear issue */
.k-popup .k-columnmenu-item-content .k-checkbox,
.k-popup .k-columnmenu-item-content .k-checkbox:checked {
  display: inherit !important;
}

.flex-max-height{
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}
.flex-max-height.entry-page{
  margin-bottom: 20px;
}

.kendo-stacklayout-horizontal, .kendo-stacklayout-vertical,
.kendo-stacklayout-horizontal kendo-stacklayout, .kendo-stacklayout-vertical kendo-stacklayout{
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
}
.kendo-stacklayout-horizontal, .kendo-stacklayout-horizontal kendo-stacklayout{
  flex-direction: row;
}
.kendo-stacklayout-vertical{
  flex-direction: column;
}

.flex-max-height .w-p-5{
  margin-bottom: 0px;
}
.k-drawer-content {
  display: flex;
  flex-direction: column;
}

.tab-list-wrapper.d-flex.flex-column{
  flex-grow: 1;
}
.tab-list-wrapper.d-flex.flex-column .flex-1{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.shrinkable-grid .flex-max-height{
  height: 200px;/*make the grid shrink when browser resize to smaller height*/
}

kendo-notification-container .k-notification-group{
  z-index: 200;
}
