.k-badge {
    &-sm {
        height: $badge-sm-height;
    }
    &-md {
        height: $badge-md-height;
    }
    &-lg {
        height: $badge-lg-height;
    }
}

@each $name, $color in $w-kendo-theme-colors {
    .k-badge-solid-#{$name} {

        @if ($name == 'warning') {
            color: $component-text;
        } @else {
            color: $white;
        }
    }
}

.badge-orange {
    border-color: $orange;
    background-color: $orange;
}

.badge-teal-pill{
    background-color: $badge-bg-color-teal;
    color: $badge-color-teal;
    border: $badge-border-color-teal;
    border-radius: $badge-border-radius-pill;
}


// Dot badge

.dot-badge {
    border-radius: 50%;
    background-color: $primary;
    &-sm {
        height: $dot-badge-size-sm;
        width: $dot-badge-size-sm;
    }
    &-md {
        height: $dot-badge-size-md;
        width: $dot-badge-size-md;
    }
    &-lg {
        height: $dot-badge-size-lg;
        width: $dot-badge-size-lg;
    }
    &-primary {
        background-color: $primary;
    }
}

williams-ui-platform-badge, williams-ui-platform-badge-container {
    line-height: 0;
}
.k-badge-inside{
    z-index:0;
    font-family: Helvetica;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 1px;
    text-align: center;
    color: #0b1e25;
}