.content-display-wrapper{
    border: 1px solid $component-border;
    border-radius: $kendo-border-radius-thin;
    overflow: hidden;
    td{
        padding: map-get($spacing,4);

        a{
            padding: map-get($spacing,2) map-get($spacing,3);
            color:$white;
            background: $primary;
            font-size: $kendo-font-size;
            font-weight: $kendo-font-weight-bold;
            border-radius: $kendo-button-border-radius;
        }
    }
}