    // Wrapper
    .k-tabstrip-wrapper {
        @include fill(
            $kendo-tabstrip-wrapper-text,
            $kendo-tabstrip-wrapper-bg,
            $kendo-tabstrip-wrapper-border
        );
        
    }

    .k-tabstrip-top {
        > .k-tabstrip-items-wrapper {
            @extend .k-tabstrip-horizontal !optional;
            border-bottom-width: $kendo-tabstrip-border-width;

            .k-item {
                @include border-top-radius( $kendo-tabstrip-item-border-radius );
                border-bottom-width: 0;
                margin-bottom: -$kendo-tabstrip-border-width;
            }
            .k-item:active,
            .k-item.k-active {
                margin-bottom: if( $kendo-tabstrip-border-width, -$kendo-tabstrip-border-width, null );
                border-bottom-width: $kendo-tabstrip-border-width;
                border-bottom-color: $kendo-tabstrip-item-hover-text !important; // stylelint-disable-line declaration-no-important
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            @include border-bottom-radius( $kendo-tabstrip-item-border-radius );
            border-top-width: 0 !important; // stylelint-disable-line declaration-no-important
        }
    }


    // Items
    .k-tabstrip-items-wrapper {
        @include fill(
            $kendo-tabstrip-text,
            $kendo-tabstrip-bg,
            $kendo-tabstrip-border
        );
       
        .k-item {
            @include fill(
                $kendo-tabstrip-item-text,
                //$tabstrip-item-bg,
                null,
                $kendo-tabstrip-item-gradient
            );
            font-weight: $tabstrip-font-weight;
            //border-bottom: $tabstrip-item-border;
            

            &:hover,
            &.k-hover {
                @include fill(
                    $kendo-tabstrip-item-hover-text,
                    $kendo-tabstrip-item-hover-bg,
                    null,
                    $kendo-tabstrip-item-hover-gradient
                );
                border-bottom: $kendo-tabstrip-item-hover-border;
            }

            &:active,
            &.k-active,
            &.k-selected {
                @include fill(
                    $kendo-tabstrip-item-selected-text,
                    $kendo-tabstrip-item-selected-bg,
                    null,
                    $kendo-tabstrip-item-selected-gradient
                );
                border-bottom: $kendo-tabstrip-item-selected-border;
                border-radius: $tabstrip-item-selected-border-radius;
            }

            &:focus,
            &.k-focus {
                @include box-shadow( $kendo-tabstrip-item-focus-shadow );
            }

            &.k-tabstrip-dragging {
                @include box-shadow( $kendo-tabstrip-item-focus-shadow );
            }
        }

    }


    // Content
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        @include fill(
            $kendo-tabstrip-content-text,
            $kendo-tabstrip-content-bg,
            $kendo-tabstrip-content-border
        );
        border: $tabstrip-content-border-none;

        &:focus,
        &.k-focus {
            outline-color: $kendo-tabstrip-content-focus-border;
            outline: none;
        }
    }


    // Selected indicator
    @if ($kendo-tabstrip-indicator-size) {

        .k-tabstrip-items-wrapper {
            .k-item.k-active::after {
                border-color: $kendo-tabstrip-indicator-color;
            }
        }
    }

    // Styles for tab position left
    .k-tabstrip-left {
        .k-tabstrip-items-wrapper {
            flex-shrink: 0;
            .k-item {
                &:active,
                &.k-active,
                &.k-selected {
                    border-right: $kendo-tabstrip-item-selected-border;
                    border-top-right-radius: 0;
                }
            }
        }
    }

