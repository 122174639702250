/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import '../../../../styles/base/variables/colors.scss';
@import '../../../../styles/base/fonts.scss';
@import '../../../../styles/base/mixins.scss';
@import '../../../../styles/base/configuration.scss';

//Libs styles
@import '../../../../styles/base/common.scss';


html {
    height: 100%;
}
body{
    height: 100%;
}

williams-ui-platform-html-viewer{
    background-color:$white;
    color: $primary-text-color;
}

.link-color{
    .w-link-button{
        font-weight: 400 !important;
        color: $primary;
    }
}

ngx-extended-pdf-viewer #viewerContainer:not(.pdfPresentationMode){
    background-color: $base-bg !important;
    margin: -2px !important;
}
ngx-extended-pdf-viewer .pdfViewer .page{
    border: none !important;
}

.discoverylogo{
    width: 260px;
    height: 150.53px;
    align-self: center;
}

.center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.verticallyCenter{
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.peopleimgcontainer{
  max-width: 200px;
  border-radius: 200px;
}

.buttonwithnostyles{
    border: none;
    background: $white;
}

.ebbhomeimage{
    width: 100%;
    object-fit: cover;
    height:295px
}

.row-count {
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
}

.emptyNoticeHomePage{
    background-color: $bluish;
}


williams-ui-platform-home .k-scrollview-pageable{
    padding-top: 34px !important;
}

williams-ui-platform-bread-crumb li.k-breadcrumb-item:nth-child(2){
    pointer-events: none !important;
    cursor: default !important;
    text-decoration: none !important;

    .k-breadcrumb-link {
        opacity: 0.6 !important;
    }
 } 