kendo-multiselect {
  .k-searchbar {
    min-width: 32px;
  }

  kendo-taglist.k-chip-list {
    flex-wrap: nowrap;
  }

  .k-chip {
    flex-shrink: 0;
  }

  .k-chip:first-child {
    max-width: calc(100% - 72px);
  }

  &.two-digit-summary {
    .k-chip:first-child {
      max-width: calc(100% - 80px);
    }
  }

  &.three-digit-summary {
    .k-chip:first-child {
      max-width: calc(100% - 86px);
    }
  }

  &.four-digit-summary {
    .k-chip:first-child {
      max-width: calc(100% - 92px);
    }
  }

  &.single-value {
    .k-chip:first-child {
      max-width: calc(100% - 36px);
    }
  }
}

// MultiSelect Select All Checkbox

.k-popup {
  &.w-multiselect {
    .k-checkbox,
    .k-checkbox:checked {
      display: $display-none;
    }
    .k-checkbox + .k-label {
      padding: $kendo-padding-sm-x $kendo-padding-x;
      display: $display-inline-block;
      width: 100%;
      margin: map-get($spacing, 0);
    }
    .k-checkbox + .k-label:hover {
      background-color: $hovered-bg;
      color: $component-text;
      cursor: pointer;
    }
    .k-checkbox:checked + .k-label {
      background-color: $primary;
      color: $component-text-white;
      box-shadow: $grid-focused-shadow;
    }
  }
}

kendo-multiselect .k-chip-actions {
  display: none;
}

.k-multiselect.k-input-md {
  .k-chip {
    padding: map-get($spacing, thin) map-get($spacing, 1);
  }
}
